import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message-dialog"
export default class extends Controller {
  connect () {
    this.timer = setTimeout(() => { this.close() }, 4000)
  }

  close () {
    clearTimeout(this.timer)
    this.element.classList.add('hidden')
  }
}
