// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
// ./bin/rails generate stimulus controllerName

import { application } from "./application"

import NestedForm from "stimulus-rails-nested-form"

import MessageController from "./message_controller"
application.register("message", MessageController)

import DropdownController from "./dropdown_controller"
application.register("dropdown", DropdownController)

import MenuController from "./menu_controller"
application.register("menu", MenuController)

// import StatisticsController from "./statistics_controller"
// application.register("statistics", StatisticsController)

import StatusController from "./status_controller"
application.register("status", StatusController)

application.register("nested-form", NestedForm)
