import "@hotwired/turbo-rails"
import "../controllers"
import "@fortawesome/fontawesome-free/js/all"

// document.addEventListener("turbo:load", function () {
//   document.querySelector('body').style.display = ''
//   document.querySelector('body').classList.remove('hidden')
// })

setInterval(() => {
  var url = new URL(window.location.href)
  if (url.pathname !== '/') {
    fetch("/ping")
      .then((response) => {
        if (response.status !== 200) {
          window.location.href = '/logout'
        }
      })
      .catch((error) => (window.location.href = '/logout'))
  }
}, 60000)
